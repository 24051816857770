import React from 'react';

import { useAppSelector } from '@src/hooks';

import { NoPools } from './NoPools';
import { StakingPools } from './StakingPools';

export const Management: React.FC = () => {
    const { pools } = useAppSelector((state) => state.staking);
    return (
        <div>
            {pools.length === 0 ? <NoPools /> : <StakingPools pools={pools} />}
        </div>
    );
};

export { PoolManagement } from './ManagePool';
export { BountySetup, TokenImageSetup, WalletLookUp } from './Settings';
