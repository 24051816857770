import React from 'react';
import dayjs from 'dayjs';
import Link from 'next/link';

import { Button } from '@src/components/Button';
import { ProgressBar } from '@src/components/ProgressBar';
import { useAppSelector } from '@src/hooks';
import { DEFAULT_TOKEN } from '@src/config';
import { effectiveApy } from '@src/utils/staking';
import { StakingPool } from '@src/ts/interfaces';

import { Icon } from '@src/components/Staking/Icon';
import { NFTTooltip } from '@src/components/Staking/NFTTooltip';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

const MobileContent: React.FC<{ pool: StakingPool }> = ({
    pool: {
        total_staked = '0',
        hardcap,
        input_token,
        end_date,
        nft_multiplier_used,
        nft_multiplier,
        apr,
    },
}) => {
    const { decimals = default_decimals, symbol = default_symbol } =
        input_token;

    const boost =
        Math.round(
            ((parseFloat(apr) * nft_multiplier) / 10 - parseFloat(apr)) * 10,
        ) / 10;

    return (
        <div className="lg:hidden p-5">
            <div className="flex my-2">
                <div className="flex-1 text-secondary">NFT boost</div>

                <div className={nft_multiplier_used ? 'text-success' : ''}>
                    +{boost}%
                </div>
            </div>
            <div className="flex my-2">
                <div className="flex-1 text-secondary">Expiry date </div>
                {dayjs(end_date).format('HH:mm MMM DD, YYYY')}
            </div>

            <ProgressBar
                hardcap={hardcap}
                total_staked={total_staked}
                decimals={decimals}
                symbol={symbol}
            />
        </div>
    );
};

const PoolItem: React.FC<{ pool: StakingPool }> = ({ pool }) => {
    const {
        apr = '0',
        name,
        total_staked = '0',
        hardcap,
        input_token,
        reward_token,
        pool_id,
        lock_period,
        nft_multiplier_used,
        nft_multiplier = 10,
        end_date,
    } = pool;

    const { current_pool, loading } = useAppSelector((state) => state.staking);
    const modal_open = useAppSelector(
        (state) => state.layout.staking_modal_open,
    );

    const { decimals = default_decimals, symbol = default_symbol } =
        input_token;

    const [current_version, idx] = pool_id.split(':');

    const APR =
        current_version === 'compound'
            ? (effectiveApy(parseFloat(apr) / 100, 365) * 100).toFixed(2) + '%'
            : apr;

    const boost =
        Math.round(
            ((parseFloat(apr) * nft_multiplier) / 10 - parseFloat(apr)) * 10,
        ) / 10;

    return (
        <Link href={`/manage/${idx}`}>
            <a>
                <div
                    className={`lg:h-24 my-4 md:p-5 w-full text-left rounded border md:hover:bg-level-two border-custom cursor-pointer lg:flex items-center bg-level-one text-primary w-full ${
                        current_pool === pool_id && modal_open
                            ? 'md:bg-level-oneer'
                            : ''
                    } ${loading ? 'animate-pulse' : ''}`}
                >
                    <div className={`flex-1 flex items-center w-full`}>
                        <div className="asp-col-1 bg-level-two md:bg-transparent p-5 rounded md:p-0">
                            <div className="flex-1 flex items-center lg:mr-2">
                                <Icon
                                    input_token={input_token}
                                    reward_token={reward_token}
                                    expired={false}
                                    complete={false}
                                />
                                <div className="flex-1">
                                    <h4 className="text-lg">
                                        <strong>{name}</strong>
                                    </h4>
                                    <p className="text-sm text-secondary">
                                        {lock_period} days lock
                                    </p>
                                </div>
                                <div className="lg:hidden text-right lg:text-left">
                                    <h1 className="text-2xl">
                                        <strong>{APR}</strong>
                                    </h1>
                                    {current_version === 'compound' && (
                                        <p className="text-sm text-secondary">
                                            Compounding
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:inline asp-col-2 text-right lg:text-left">
                            <h1 className="text-2xl">
                                <strong>{APR}</strong>
                            </h1>
                            {current_version === 'compound' && (
                                <p className="text-sm text-secondary">
                                    Compounding
                                </p>
                            )}
                        </div>
                        <div className="hidden lg:inline asp-col-3 pr-8">
                            <NFTTooltip pool_id={pool_id}>
                                <div
                                    className={`max-w-min px-4 py-2 rounded text-center bg-level-two border ${
                                        nft_multiplier_used
                                            ? 'border-success'
                                            : 'border-primary border-opacity-25'
                                    }`}
                                >
                                    <div className="flex items-center space-x-2 justify-center">
                                        <div className="rounded-full border border-primary border-opacity-25">
                                            <div
                                                className={`rounded-full p-2 opacity-100 ${
                                                    nft_multiplier_used
                                                        ? 'bg-success'
                                                        : 'bg-main'
                                                }`}
                                            ></div>
                                        </div>
                                        <div className="">+{boost}%</div>
                                    </div>
                                </div>
                            </NFTTooltip>
                        </div>
                        <div className="hidden lg:inline asp-col-4">
                            <ProgressBar
                                hardcap={hardcap}
                                total_staked={total_staked}
                                decimals={decimals}
                                symbol={symbol}
                            />
                        </div>

                        <div className={`hidden lg:inline asp-col-5`}>
                            {dayjs(end_date).format('HH:mm MMM DD, YYYY')}
                        </div>
                        <div className={`hidden lg:inline asp-col-6`}>
                            <Button className="float-right">
                                <img
                                    src="/icons/manage.svg"
                                    data-testid="edit-pool"
                                    className="mx-auto"
                                />
                            </Button>
                        </div>
                    </div>
                    <MobileContent pool={pool} />
                </div>
            </a>
        </Link>
    );
};

export default PoolItem;
