import React, { useState, useEffect } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { toast } from 'react-toastify';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

import { getContract } from '@src/contracts';
import { Button, Card, Tooltip } from '@src/components';
import {
    useAppDispatch,
    useAppSelector,
    useDecubateContract,
    useTokenPrice,
} from '@src/hooks';
import { bnSum, parseBalance, parseWei } from '@src/utils/web3';
import { ContractType } from '@src/ts/constants';
import { DEFAULT_TOKEN } from '@src/config';
import { updatePools } from '@src/features/staking';

const compound = getContract(ContractType.Vault);
const stake = getContract(ContractType.CompoundStaking);

const { symbol: default_symbol } = DEFAULT_TOKEN;

const card_classes =
    'bg-bounty-card border border-custom p-6 h-44 flex items-center';

const getBounty = async () => {
    const len = await stake.poolLength();

    return Promise.all(
        [...Array(len.toNumber()).keys()].map((p_id) =>
            compound.calculateHarvestDcbRewards(p_id),
        ),
    );
};

export const BountyCard: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [bounty, setBounty] = useState(BigNumber.from(0));
    const [initial, setInitial] = useState(true);
    const token_price = useTokenPrice();
    const dispatch = useAppDispatch();

    const { pools } = useAppSelector((state) => state.staking);

    const { account, connect } = useWeb3Onboard();

    const [compounder] = useDecubateContract(ContractType.Vault, true);

    const update = () => getBounty().then((r) => setBounty(bnSum(r)));

    useEffect(() => {
        if (initial) {
            update();
            setInitial(false);
        } else {
            const handler = setTimeout(update, 10000);
            return () => clearTimeout(handler);
        }
    }, [bounty, initial]);

    const reward_usd = bounty.mul(token_price).div(parseWei('1'));

    const handleClaimBounty = async () => {
        if (!account) {
            connect({});
            return;
        }

        setLoading(true);
        toast.info('Awaiting successful transaction');
        try {
            const gasLimit = await compounder.estimateGas.harvestAll();
            const tx = await compounder.harvestAll({
                gasLimit: gasLimit.mul(120).div(100),
            });
            await tx.wait();
            toast.success('Successfully compounded the staking pools');
            dispatch(updatePools(account));
        } catch (err) {
            toast.error(err.error?.data?.message || err.message);
        }
        setLoading(false);
    };

    const compound_pools = pools.filter(
        ({ pool_id }) => pool_id.split(':')[0] === 'compound',
    );

    const all_pools_full =
        compound_pools.filter(({ total_staked, hardcap }) =>
            BigNumber.from(total_staked).gte(
                BigNumber.from(hardcap).mul(9999).div(10000), // 99.99% full
            ),
        ).length === compound_pools.length;

    return (
        <div className={`text-primary`}>
            <h3 className="text-xl mb-4">
                <strong>Compound bounty</strong>
            </h3>
            <Card className={card_classes}>
                <div className="w-full">
                    <p className="text-secondary text-sm mb-6 max-w-xs">
                        Claim {default_symbol} bounty for helping to compound
                        staking rewards for everyone in the compounding pools.
                    </p>
                    <div className="flex w-full space-x-4 items-center justify-between">
                        <div className="">
                            <h4 className="text-xl mb-2">
                                <strong data-testid="bounty-value">
                                    {parseBalance(bounty, 18, 6)}{' '}
                                    {default_symbol}
                                </strong>
                            </h4>
                            <p className="text-secondary text-xs">
                                ~ {parseBalance(reward_usd, 18, 6)} USD
                            </p>
                        </div>
                        <div className="flex-1">
                            <Tooltip
                                text="The pools can't be compounded right now as they are currently full"
                                active={all_pools_full}
                            >
                                <Button
                                    id="claim-bounty"
                                    className="w-36 float-right"
                                    onClick={handleClaimBounty}
                                    loading={loading}
                                    disabled={
                                        (account && all_pools_full) || loading
                                    }
                                >
                                    {account ? 'Claim bounty' : 'Connect'}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};
