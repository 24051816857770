import { PoolToken } from '@src/ts/interfaces';

export const Icon: React.FC<{
    input_token: PoolToken;
    reward_token: PoolToken;
    expired?: boolean;
    complete?: boolean;
}> = ({ input_token, reward_token, expired = false, complete = false }) => {
    return (
        <div className="relative">
            {(expired || complete) && (
                <img
                    src={`/icons/${
                        complete ? 'stake-complete' : 'lock-icon'
                    }.svg`}
                    className="h-14 w-14 absolute left-1 mx-auto -bottom-3 z-10"
                />
            )}
            <div
                className={`pr-10 flex ${
                    expired || complete ? 'opacity-20' : ''
                }`}
            >
                <div className="w-10 h-10">
                    <img src={input_token.logo} alt={input_token.symbol} />
                </div>
                <div className="w-10 h-10 absolute top-0 bottom-0 left-6">
                    <img src={reward_token.logo} alt={reward_token.symbol} />
                </div>
            </div>
        </div>
    );
};
