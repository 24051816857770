import { AddPoolButton } from '../AddPoolButton';

export const NoPools: React.FC = () => (
    <div className="text-center">
        <img src="/img/Welcome.png" className="mx-auto my-12" />

        <h4 className="text-2xl max-w-sm mx-auto">
            <strong>Welcome to your new staking management panel</strong>
        </h4>
        <p className="mt-4 mb-8 text-secondary">
            Let’s get started by creating your first staking pool
        </p>

        <AddPoolButton className="mx-auto" />
    </div>
);
