import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@src/hooks';
import { BigNumber } from '@ethersproject/bignumber';
import { parseBalance } from '@src/utils/web3';
import { ACCOUNT_IMAGE, DEFAULT_TOKEN, PLATFORM_IMAGE } from '@src/config';

import { aggregateValues, reducePools, getTokenPrices } from './util';
import { BountyCard } from './BountyCard';
import { AnalyticsCard } from './AnalyticsCard';
import { cancellablePromise } from '@src/utils/promise';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

export const StakingAnalytics: React.FC = () => {
    const { pools } = useAppSelector((state) => state.staking);

    const [prices, setPrices] = useState({});
    const [data, setData] = useState(reducePools(pools));
    const token_decimals = pools.reduce(
        (
            acc,
            {
                input_token: { address: i_a, decimals: i_d },
                reward_token: { address: r_a, decimals: r_d },
            },
        ) => ({ ...acc, [i_a]: i_d, [r_a]: r_d }),
        {},
    );

    const token_staked =
        data[default_symbol]?.total_staked || BigNumber.from(0);

    useEffect(() => {
        setData(reducePools(pools));
    }, [pools]);

    useEffect(() => {
        const { cancel, promise } = cancellablePromise(getTokenPrices(data));

        promise.then((prices) => setPrices(prices)).catch(() => true);

        return cancel;
    }, [data]);

    return (
        <div className="h-auto">
            <div className="overflow-x-auto">
                <div className="h-full grid grid-cols-3 gap-4 min-w-max">
                    <BountyCard />

                    <AnalyticsCard
                        title="My account"
                        items={[
                            {
                                title: 'Total rewards earned',
                                text: aggregateValues(
                                    'earned_rewards',
                                    data,
                                    prices,
                                    token_decimals,
                                ),
                            },
                            {
                                title: 'Total value staked',
                                text: aggregateValues(
                                    'user_stake',
                                    data,
                                    prices,
                                    token_decimals,
                                ),
                            },
                        ]}
                        image={ACCOUNT_IMAGE}
                    />
                    <AnalyticsCard
                        title="Platform"
                        items={[
                            {
                                title: 'Total value locked',
                                text: aggregateValues(
                                    'total_staked',
                                    data,
                                    prices,
                                    token_decimals,
                                ),
                            },
                            {
                                title: `Total ${default_symbol} staked`,
                                text: `${parseBalance(
                                    token_staked,
                                    default_decimals,
                                )} ${default_symbol}`,
                            },
                        ]}
                        image={PLATFORM_IMAGE}
                    />
                </div>
            </div>
        </div>
    );
};
