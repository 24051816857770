import { InputProps } from '@src/ts/props';

import { Label } from '../Label';

export type InputType = 'number' | 'text' | 'datetime-local';

export const Checkbox: React.FC<InputProps> = ({
    id,
    label,
    tooltip,
    error,
    placeholder,
    name,
    value,
    onChange,
}) => {
    return (
        <div className="">
            <div className="flex items-cenetr">
                {label && <Label id={id} label={label} tooltip={tooltip} />}
                {error && <p className="text-red">{error}</p>}
            </div>

            <div className="mt-2 flex items-center bg-level-two rounded px-4 py-2 space-x-4 shadow py-3">
                <div className="flex-1">{placeholder}</div>{' '}
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                        type="checkbox"
                        className="focus-none toggle-checkbox absolute block w-6 h-6 rounded-full bg-primary border-4 appearance-none cursor-pointer"
                        name={name}
                        id={id}
                        value={value}
                        onChange={onChange}
                        checked={Boolean(value)}
                        data-testid={id}
                    />
                    <label
                        htmlFor="toggle"
                        className="toggle-label block overflow-hidden h-6 rounded-full bg-main cursor-pointer"
                    ></label>
                </div>
            </div>
        </div>
    );
};
