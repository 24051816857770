import React from 'react';
import dayjs from 'dayjs';
import { BigNumber } from '@ethersproject/bignumber';

import { Button } from '@src/components/Button';
import { ProgressBar } from '@src/components/ProgressBar';
import { ArrowRight } from '@src/components/Icon';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { setStakingDrawerOpen } from '@src/features/layout';
import { setCurrentPool, setModalState } from '@src/features/staking';
import { DEFAULT_TOKEN } from '@src/config';
import { parseBalance } from '@src/utils/web3';
import { effectiveApy } from '@src/utils/staking';
import { StakingPool } from '@src/ts/interfaces';

import { Icon } from '../Icon';
import { NFTTooltip } from '../NFTTooltip';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

const PoolItem: React.FC<{ pool: StakingPool }> = ({
    pool: {
        apr = '0',
        name,
        total_staked = '0',
        hardcap,
        input_token,
        reward_token,
        has_stake,
        pool_id,
        can_claim,
        user_stake = '0',
        lock_period,
        earned_reward = '0',
        nft_multiplier_used,
        nft_multiplier = 10,
        last_user_deposit,
        user_multiplier = '10',
        end_date,
    },
}) => {
    const dispatch = useAppDispatch();
    const { current_pool, loading } = useAppSelector((state) => state.staking);
    const modal_open = useAppSelector(
        (state) => state.layout.staking_modal_open,
    );

    const { decimals = default_decimals, symbol = default_symbol } =
        input_token;

    const [current_version] = pool_id.split(':');

    const APR =
        current_version === 'compound'
            ? (effectiveApy(parseFloat(apr) / 100, 365) * 100).toFixed(2) + '%'
            : apr;

    const boost =
        Math.round(
            ((parseFloat(apr) * nft_multiplier) / 10 - parseFloat(apr)) * 10,
        ) / 10;

    const lock_end_date = dayjs(last_user_deposit)
        .add(Number(lock_period), 'days')
        .format('MMM DD, YYYY');

    const has_boost =
        nft_multiplier_used &&
        BigNumber.from(user_multiplier).eq(nft_multiplier);

    const stake = BigNumber.from(user_stake).add(
        current_version === 'multi-asset' ? 0 : earned_reward,
    );

    const expired = dayjs(end_date).diff(dayjs()) <= 0;

    const handleClick = () => {
        if (has_stake && can_claim) {
            dispatch(setModalState('Withdraw'));
        }
        dispatch(setStakingDrawerOpen(true));
        dispatch(setCurrentPool(pool_id));
    };

    return (
        <div
            className={`lg:h-24 my-4 md:p-5 w-full text-left rounded border md:hover:bg-level-two border-custom cursor-pointer lg:flex items-center bg-level-one text-primary ${
                current_pool === pool_id && modal_open
                    ? 'md:bg-level-oneer'
                    : ''
            } ${loading ? 'animate-pulse' : ''}`}
            onClick={handleClick}
        >
            <div className={`flex-1 flex items-center w-full`}>
                <div className="sp-col-1 bg-level-two md:bg-transparent p-5 rounded md:p-0">
                    <div className="flex-1 flex items-center lg:mr-2">
                        <Icon
                            input_token={input_token}
                            reward_token={reward_token}
                            expired={expired}
                            complete={has_stake && can_claim}
                        />
                        <div className="flex-1">
                            <h4 className="text-lg">
                                <strong>{name}</strong>
                            </h4>
                            <p className="text-sm text-secondary">
                                {lock_period} days lock
                            </p>
                        </div>
                        <div className="lg:hidden text-right lg:text-left">
                            <h1 className="text-2xl">
                                <strong>{APR}</strong>
                            </h1>
                            {current_version === 'compound' && (
                                <p className="text-sm text-secondary">
                                    Compounding
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hidden lg:inline sp-col-2 text-right lg:text-left">
                    <h1 className="text-2xl">
                        <strong>{APR}</strong>
                    </h1>
                    {current_version === 'compound' && (
                        <p className="text-sm text-secondary">Compounding</p>
                    )}
                </div>
                <div className="hidden lg:inline sp-col-3 pr-8">
                    <NFTTooltip pool_id={pool_id}>
                        <div
                            className={`max-w-min px-4 py-2 rounded text-center bg-level-two border ${
                                has_boost
                                    ? 'border-success'
                                    : 'border-primary border-opacity-25'
                            }`}
                        >
                            <div className="flex items-center space-x-2 justify-center">
                                <div className="rounded-full border border-primary border-opacity-25">
                                    <div
                                        className={`rounded-full p-2 opacity-100 ${
                                            has_boost ? 'bg-success' : 'bg-main'
                                        }`}
                                    ></div>
                                </div>
                                <div className="">+{boost}%</div>
                            </div>
                        </div>
                    </NFTTooltip>
                </div>
                <div className="hidden lg:inline sp-col-4">
                    {(!expired && (
                        <ProgressBar
                            hardcap={hardcap}
                            total_staked={total_staked}
                            decimals={decimals}
                            symbol={symbol}
                        />
                    )) || <p>Pool expired</p>}
                </div>
                <div className="hidden lg:inline sp-col-5">
                    {parseBalance(stake, decimals)} {symbol}
                </div>
                <div
                    className={`hidden lg:inline sp-col-${
                        has_stake && can_claim ? '7' : '6'
                    }`}
                >
                    {has_stake
                        ? can_claim
                            ? 'Unlocked'
                            : lock_end_date
                        : 'None'}
                </div>
                <div
                    className={`hidden lg:inline sp-col-${
                        has_stake && can_claim ? '6' : '7'
                    }`}
                >
                    <Button className="w-full" id="right_arrow">
                        {has_stake && can_claim ? (
                            'Claim & withdraw'
                        ) : (
                            <ArrowRight className="mx-auto" />
                        )}
                    </Button>
                </div>
            </div>
            <div className="lg:hidden p-5">
                {has_stake && can_claim ? (
                    <div className="text-center ">
                        <p className="text-secondary mb-4">
                            Your staking period in this pool is complete
                        </p>
                        <Button className="w-full" id="claim_withdraw">
                            Claim &amp; withdraw
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className="flex my-2">
                            <div className="flex-1 text-secondary">
                                NFT boost
                            </div>

                            <div className={has_boost ? 'text-success' : ''}>
                                +{boost}%
                            </div>
                        </div>
                        <div className="flex my-2">
                            <div className="flex-1 text-secondary">
                                Your stake
                            </div>
                            {parseBalance(stake, decimals)} {symbol}
                        </div>
                        <div className="flex my-2">
                            <div className="flex-1 text-secondary">
                                Lock end date{' '}
                            </div>
                            {has_stake ? lock_end_date : 'None'}
                        </div>
                        {(!expired && (
                            <ProgressBar
                                hardcap={hardcap}
                                total_staked={total_staked}
                                decimals={decimals}
                                symbol={symbol}
                            />
                        )) || (
                            <div className="flex my-2">
                                <div className="flex-1 text-secondary">
                                    Capacity
                                </div>
                                Pool expired
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PoolItem;
