import React from 'react';

import { Button } from '@src/components/Button';
import { Input } from '@src/components/Input';
import { PoolFormProps } from '@src/ts/props';
import { getPoolFormData } from '@src/utils/form';

const Inset = (text: string) => (
    <div className="px-4">
        <strong>{text}</strong>
    </div>
);

const Warning: React.FC = () => {
    return (
        <div className="border border-secondary rounded p-4">
            <h4>
                <strong>Important!</strong>
            </h4>

            <ul className="ml-4 text-sm text-secondary space-y-2 mt-4 mb-2">
                <li>
                    You need to set up and create a pool before you can set up
                    the NFT boost
                </li>
                <li>
                    These parameters interact with a smart contract. Each change
                    will require to complete a transaction to save changes.
                </li>
            </ul>
        </div>
    );
};

export const PoolForm: React.FC<PoolFormProps> = ({
    state = {},
    handleChange, // should take name + value
    add = false,
    handleClick,
    errors,
    loading,
    disabled,
    ticker,
}) => {
    const pool_form_data = getPoolFormData(ticker || 'XXX');

    return (
        <div className="mt-8">
            {pool_form_data.map(
                ({ name, label, type, placeholder, tooltip, inset, step }) => (
                    <Input
                        className={`my-4`}
                        key={name}
                        value={state[name] as string}
                        name={name}
                        id={name}
                        label={label}
                        error={errors[name] as string}
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        placeholder={placeholder}
                        type={type}
                        inset={inset && Inset(inset)}
                        tooltip={tooltip}
                        step={step}
                    />
                ),
            )}

            {add && <Warning />}

            <Button
                id="add_pool"
                className={'w-full mt-8 mb-4'}
                onClick={handleClick}
                loading={loading}
                disabled={disabled || loading}
            >
                {add ? 'Add Pool' : 'Save changes'}
            </Button>
        </div>
    );
};
