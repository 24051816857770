export const AnalyticsItem: React.FC<{ text: string; title: string }> = ({
    text,
    title,
}) => {
    return (
        <div className="">
            <h3 className="text-lg mb-2">
                <strong>{text}</strong>
            </h3>
            <p className="text-xs text-secondary">{title.toUpperCase()}</p>
        </div>
    );
};
