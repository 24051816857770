export const Switch: React.FC<{
    curr: number;
    setCurr: (v: number) => void;
    opts: string[];
}> = ({ curr, setCurr, opts }) => (
    <div className="rounded border border-custom">
        <div className="p-0 bg-main border-4 border-main flex items-center rounded">
            {opts.map((o, idx) => (
                <button
                    key={o}
                    onClick={() => setCurr(idx)}
                    className={`rounded flex-1 px-4 py-2 ${
                        idx === curr
                            ? 'bg-level-one border border-custom'
                            : 'text-secondary'
                    } `}
                >
                    {o}
                </button>
            ))}
        </div>
    </div>
);
