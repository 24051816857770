import React from 'react';

export const NoPools: React.FC<{ type: string }> = ({ type }) => (
    <div
        className={`lg:h-24 my-4 p-3 md:p-5 w-full text-left rounded border border-custom cursor-pointer bg-level-one flex items-center space-x-4`}
    >
        <div className="flex-1">
            <h5 className="text-lg">
                <strong>
                    It looks like there are no {type} pools at this time
                </strong>
            </h5>
        </div>
    </div>
);
