import { IS_DARK_MODE, LEVEL_ONE_COLOR } from '@src/config';
import React from 'react';
import ReactTooltip from 'react-tooltip';

export const Tooltip: React.FC<{
    text: string;
    active?: boolean;
    className?: string;
}> = ({ children, text, active = true, className }) => {
    return (
        <div className={className}>
            <div data-html={true} data-tip={active ? text : undefined}>
                {children}
            </div>
            <ReactTooltip
                className="text-secondary rounded opacity-100"
                backgroundColor={LEVEL_ONE_COLOR}
                textColor={IS_DARK_MODE ? '#ffffff' : '#000000'}
                html={true}
            />
        </div>
    );
};
