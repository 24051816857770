import React from 'react';
import dayjs from 'dayjs';

import { StakingPool } from '@src/ts/interfaces';

import { RoutingButtons } from '../RoutingButtons';

import { NoPools } from './Cards';
import PoolHeader from './PoolHeader';
import PoolItem from './PoolItem';

const PoolsContainer: React.FC<{ title: string; controls?: JSX.Element }> = ({
    title,
    children,
    controls,
}) => {
    return (
        <div className="text-primary mb-8 mt-12">
            <div className="flex items-start justify-between">
                <h2 className="text-3xl mb-8">
                    <strong>{title}</strong>
                </h2>
                {controls}
            </div>

            <PoolHeader />

            {children}
        </div>
    );
};

export const StakingPools: React.FC<{ pools: StakingPool[] }> = ({ pools }) => {
    const { active, expired } = pools.reduce(
        (acc, curr) => {
            if (dayjs().isBefore(dayjs(curr.end_date))) {
                return { ...acc, active: [...acc.active, curr] };
            } else {
                return { ...acc, expired: [...acc.expired, curr] };
            }
        },
        { active: [], expired: [] },
    );

    return (
        <div className="my-8">
            <PoolsContainer title="Active pools" controls={<RoutingButtons />}>
                {active.length > 0 ? (
                    active.map((p) => <PoolItem pool={p} key={p.pool_id} />)
                ) : (
                    <NoPools type="active" />
                )}
            </PoolsContainer>
            <PoolsContainer title="Expired pools">
                {expired.length > 0 ? (
                    expired.map((p) => <PoolItem pool={p} key={p.pool_id} />)
                ) : (
                    <NoPools type="expired" />
                )}
            </PoolsContainer>
        </div>
    );
};
