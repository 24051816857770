import React from 'react';
import Link from 'next/link';

import { Button } from '@src/components/Button';
import { PlusIcon } from '@src/components/Icon';

export const AddPoolButton: React.FC<{ className?: string }> = ({
    className,
}) => (
    <Link href="/manage/new">
        <a>
            <Button
                id="add_pool"
                data-testid="add-pool-button"
                className={`flex items-center space-x-2 ${className || ''}`}
            >
                <PlusIcon />
                <div>Add new pool</div>
            </Button>
        </a>
    </Link>
);
