export const Card: React.FC<{ className?: string; onClick?: () => void }> = ({
    children,
    className,
    onClick = () => {
        return;
    },
}) => (
    <div
        className={`rounded border border-custom ${
            className || 'bg-level-one p-4'
        }`}
        onClick={onClick}
    >
        {children}
    </div>
);
