import React from 'react';

import { InfoText } from '@src/components/InfoText';

const PoolHeader: React.FC = ({ children }) => {
    return (
        <div className="hidden lg:flex w-full items-center px-5 py-1 text-secondary">
            <div className="asp-col-1">Pools</div>
            <div className="asp-col-2">
                <InfoText
                    info="The estimated yearly returns made from the pool."
                    text="Reward"
                    text_color="text-secondary"
                />
            </div>
            <div className="asp-col-3">
                <InfoText
                    info="Specified APY boost applies if user owns an appropriate NFT"
                    text="NFT boost"
                    text_color="text-secondary"
                />
            </div>
            <div className="asp-col-4">Capacity</div>
            <div className="asp-col-5">Expiry date</div>
            <div className="asp-col-6">{children}</div>
        </div>
    );
};

export default PoolHeader;
