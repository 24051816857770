import React from 'react';

import { Progress } from '@src/components/Progress';
import { parseBalance } from '@src/utils/web3';
import { formatUnits, parseEther } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

const Inifity: React.FC = () => {
    return (
        <svg
            className="ml-1"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.9766 4.85938C15.9766 5.67708 15.8047 6.41667 15.4609 7.07812C15.1224 7.73438 14.6458 8.24479 14.0312 8.60938C13.4219 8.97396 12.7292 9.15625 11.9531 9.15625C10.4479 9.15625 9.2474 8.34375 8.35156 6.71875C7.45573 8.34375 6.25521 9.15625 4.75 9.15625C3.55729 9.15625 2.58854 8.7526 1.84375 7.94531C1.10417 7.13281 0.734375 6.08073 0.734375 4.78906V4.70312C0.734375 3.90104 0.901042 3.16927 1.23438 2.50781C1.56771 1.84115 2.03906 1.32292 2.64844 0.953125C3.26302 0.578125 3.95833 0.390625 4.73438 0.390625C5.46875 0.390625 6.14323 0.588542 6.75781 0.984375C7.3776 1.375 7.90885 1.96615 8.35156 2.75781C8.79948 1.95573 9.32812 1.36198 9.9375 0.976562C10.5521 0.585938 11.2292 0.390625 11.9688 0.390625C13.1406 0.390625 14.1016 0.794271 14.8516 1.60156C15.6016 2.40885 15.9766 3.49479 15.9766 4.85938ZM13.7734 4.70312C13.7734 3.97396 13.5964 3.38802 13.2422 2.94531C12.888 2.4974 12.4245 2.27344 11.8516 2.27344C11.1276 2.27344 10.513 2.66146 10.0078 3.4375C9.7474 3.84375 9.57292 4.23438 9.48438 4.60938V4.83594C9.51042 5.00781 9.60156 5.26562 9.75781 5.60938C9.91406 5.94792 10.099 6.24219 10.3125 6.49219C10.5312 6.74219 10.7656 6.9375 11.0156 7.07812C11.2708 7.21354 11.5443 7.28125 11.8359 7.28125C12.4297 7.28125 12.901 7.05729 13.25 6.60938C13.599 6.16146 13.7734 5.52604 13.7734 4.70312ZM2.92969 4.85938C2.92969 5.58333 3.10156 6.16927 3.44531 6.61719C3.79427 7.0599 4.26823 7.28125 4.86719 7.28125C5.6276 7.28125 6.27083 6.8151 6.79688 5.88281C7.03125 5.46094 7.16927 5.11198 7.21094 4.83594V4.60938C7.11719 4.21354 6.94531 3.82292 6.69531 3.4375C6.45052 3.05208 6.16927 2.76302 5.85156 2.57031C5.53906 2.3724 5.20573 2.27344 4.85156 2.27344C4.27865 2.27344 3.8151 2.49479 3.46094 2.9375C3.10677 3.38021 2.92969 4.02083 2.92969 4.85938Z"
                fill="#F3F3F3"
            />
        </svg>
    );
};

export const ProgressBar: React.FC<{
    hardcap: string;
    total_staked: string;
    decimals: number;
    symbol: string;
}> = ({ hardcap, total_staked, decimals, symbol }) => {
    const percent = Math.round(
        (Number(formatUnits(total_staked, decimals)) /
            Number(formatUnits(hardcap, decimals))) *
            100,
    );

    const total =
        BigNumber.from(hardcap).gt(0) &&
        BigNumber.from(total_staked).gt(hardcap)
            ? hardcap
            : total_staked;

    const is_unlimited = hardcap === parseEther('1000000000').toString(); // TODO: maybe use input token max supply?

    return (
        <div className={'lg:pr-8'}>
            <div className="flex mb-1">
                <div className="lg:hidden flex-1 text-secondary">Capacity</div>
                <h5 className="text-sm lg:text-base">
                    <strong className="flex items-center">
                        {parseBalance(total, decimals)} /
                        {!is_unlimited ? (
                            parseBalance(hardcap, decimals) + ' ' + symbol
                        ) : (
                            <Inifity />
                        )}{' '}
                    </strong>
                </h5>
            </div>

            {!is_unlimited && (
                <div className="flex items-center">
                    <div className="flex-1 mr-4">
                        <Progress percent={percent} height={'h-4'} />
                    </div>
                    <div className="text-primary">{percent}%</div>
                </div>
            )}
        </div>
    );
};
