import { useState } from 'react';
import { useRouter } from 'next/router';

import { Switch } from '@src/components/Switch';
import { Dictionary } from '@src/ts/interfaces';
import { PoolManagementProps } from '@src/ts/props';

import { BackButton } from '../BackButton';

import { NFTBoost } from './NFTBoost';
import { PoolForm } from './PoolForm';

const nav_opts = ['Pool setup', 'NFT boost setup'];

export const PoolManagement: React.FC<PoolManagementProps> = ({
    state = {},
    handleChange,
    handleNFTChange,
    add = false,
    handleClick,
    errors,
    loading,
    ticker,
    disabled,
    nft_has_changes,
    idx,
}) => {
    const {
        query: { is_nft_boost },
    } = useRouter();

    const [nav, setNav] = useState(is_nft_boost === 'true' ? 1 : 0);

    const handleBack = () => setNav(0);

    return (
        <div className="max-w-xl mx-auto">
            <BackButton url="/manage" />

            <div className="bg-level-one rounded p-5 border border-custom mb-40">
                <Switch curr={nav} setCurr={setNav} opts={nav_opts} />

                {nav === 0 ? (
                    <PoolForm
                        state={state}
                        handleChange={handleChange}
                        add={add}
                        handleClick={handleClick}
                        errors={errors}
                        loading={loading}
                        disabled={disabled}
                        ticker={ticker}
                    />
                ) : (
                    <NFTBoost
                        add={add}
                        back={handleBack}
                        state={state['nft'] as Dictionary}
                        handleChange={handleNFTChange}
                        apy={(state?.apy as string) || '0'}
                        nft_has_changes={nft_has_changes}
                        idx={idx}
                    />
                )}
            </div>
        </div>
    );
};
