import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Input } from '@src/components/Input';
import { useDecubateContract } from '@src/hooks/useContract';
import { ContractType } from '@src/ts/constants';
import { BigNumber } from '@ethersproject/bignumber';
import { Button } from '@src/components/Button';
import { cancellablePromise } from '@src/utils/promise';

export const BountySetup: React.FC = () => {
    const [original, setOriginal] = useState('0');
    const [percent, setPercent] = useState('0');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [set, setSet] = useState(false);

    const [compounder] = useDecubateContract(ContractType.Vault, true);
    const changed = percent !== original;

    useEffect(() => {
        if (compounder && !set) {
            const p = compounder.callFee().then(async (fee: BigNumber) => {
                const parsed = (fee.toNumber() / 100).toString();
                return parsed;
            });
            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((parsed: string) => {
                    setPercent(parsed);
                    setOriginal(parsed);
                    setSet(true);
                })
                .catch(() => true);

            return cancel;
        }
    }, [compounder, loading]);

    const handleClick = async () => {
        setError('');
        if ([null, undefined, ''].includes(percent)) {
            setError('required');
            return;
        }
        setLoading(true);
        try {
            toast.info('Awaiting succesful transaction');

            const tx = await compounder.setCallFee(
                Math.round(Number(percent) * 100),
            );

            await tx.wait();

            toast.success('Successfully updated the bounty values');
        } catch (err) {
            toast.error(err);
        }
        setLoading(false);
    };

    return (
        <div className="max-w-2xl mx-auto my-20">
            <h3 className="text-xl mb-8">
                <strong>Bounty fee setup</strong>
            </h3>
            <div className="p-4 rounded bg-level-one border border-custom">
                <Input
                    value={percent}
                    onChange={(e) => setPercent(e.target.value)}
                    label="Bounty percentage"
                    name="bounty-percentage"
                    inset={<div className="px-4">Percent</div>}
                    tooltip="The percentage of rewards earned so far that have not been compounded, which will be given to the bounty claimer. Supports 2 decimal places"
                    error={error}
                    type="number"
                    step={0.05}
                    id="boutny_set"
                />

                <Button
                    onClick={handleClick}
                    className="mt-4 w-full"
                    disabled={!changed || loading}
                    loading={loading}
                    id="save_bounty"
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
};
