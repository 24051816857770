import React from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { Tooltip } from '@src/components';
import { useAppSelector } from '@src/hooks';

import { effectiveApy } from '@src/utils/staking';

export const NFTTooltip: React.FC<{ pool_id: string; admin?: boolean }> = ({
    children,
    pool_id,
    admin,
}) => {
    const { pools } = useAppSelector((state) => state.staking);
    const {
        apr,
        nft_multiplier = 10,
        nft_multiplier_used,
        user_multiplier = '10',
        nft_name,
    } = pools.find(({ pool_id: id }) => id === pool_id) || {};

    const [current_version] = pool_id.split(':');

    const boost =
        Math.round(
            ((parseFloat(apr) * nft_multiplier) / 10 - parseFloat(apr)) * 10,
        ) / 10;

    const APR_with_boost =
        current_version === 'compound'
            ? (effectiveApy(parseFloat(apr) / 100, 365) * 100 + boost).toFixed(
                  2,
              ) + '%'
            : (parseFloat(apr) + boost).toFixed(2) + '%';

    const has_boost =
        admin ||
        (nft_multiplier_used &&
            BigNumber.from(user_multiplier).eq(nft_multiplier));

    return (
        <Tooltip
            text={`<div class="text-primary p-1"><div class="flex space-x-4"><div class="flex-1 text-secondary">Required NFT</div><div class="${
                has_boost ? 'text-success' : ''
            }"><strong>${
                nft_name || 'None'
            }</strong></div></div><div class="flex space-x-4 mt-2"><div class="flex-1 text-secondary">APY with boost</div><div class="${
                has_boost ? 'text-success' : ''
            }"><strong>${APR_with_boost}</strong></div></div></div>`}
        >
            {children}
        </Tooltip>
    );
};
