import React from 'react';

import { StakingPool } from '@src/ts/interfaces';

import { TokenImage } from './TokenImage';

export const TokenImageSetup: React.FC<{ pools: StakingPool[] }> = ({
    pools,
}) => {
    const pool_tokens = pools.reduce((acc, { input_token, reward_token }) => {
        [input_token, reward_token].forEach((token) => {
            if (!acc.find(({ address }) => address === token.address)) {
                acc.push(token);
            }
        });
        return acc;
    }, []);

    return (
        <div className="max-w-2xl mx-auto my-20">
            <h3 className="text-xl mb-8">
                <strong>Token image setup</strong>
            </h3>

            <div className="p-4 rounded bg-level-one border border-custom">
                {pool_tokens.map((token) => (
                    <TokenImage key={token.address} {...token} />
                ))}
            </div>
        </div>
    );
};
