import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

import { useDecubateContract } from '@src/hooks/useContract';
import { ContractType } from '@src/ts/constants';
import { PoolToken } from '@src/ts/interfaces';
import { Input } from '@src/components/Input';
import { Button } from '@src/components/Button';

export const TokenImage: React.FC<PoolToken> = ({ address, symbol, logo }) => {
    const [logo_store] = useDecubateContract(ContractType.LogoStore, true);
    const [logo_url, setLogoUrl] = useState(logo || '');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const router = useRouter();

    const changes = logo_url !== logo;

    const handleClick = async () => {
        setError('');
        if ([null, undefined, ''].includes(logo_url)) {
            setError('required');
            return;
        }
        setLoading(true);
        try {
            toast.info('Awaiting successful transaction');

            const tx = await logo_store.setLogo(address, logo_url);
            await tx.wait();

            toast.success('Successfuly updated token image');
            router.replace(router.asPath);
        } catch (err) {
            toast.error(err.error?.data?.message || err.message);
        }
        setLoading(false);
    };

    return (
        <div className="p-4 rounded bg-level-two border border-custom ">
            <div className="text-lg flex">
                <h5 className="flex-1">
                    <strong>{symbol}</strong>
                </h5>
                <p className="text-secondary">{address}</p>
            </div>
            <div className="flex mt-4 space-x-4 items-stretch">
                <img
                    src={logo || '/icons/no-token.svg'}
                    className="w-12 h-12"
                />
                <Input
                    name="logo"
                    value={logo_url}
                    onChange={(e) => setLogoUrl(e.target.value)}
                    className="flex-1"
                    placeholder="Enter token image URL"
                    error={error}
                    id="logo_url"
                />
                <Button
                    id="save_logo"
                    disabled={!changes || loading}
                    loading={loading}
                    onClick={handleClick}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};
