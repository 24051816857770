import dayjs from 'dayjs';

import { ContractType } from '@src/ts/constants';
import { StakingPool } from '@src/ts/interfaces';

import { StakingVersion } from '@src/ts/types';

import { getContract, getERC20Contract } from '../contracts';

const contract_types: { [key in StakingVersion]: ContractType } = {
    compound: ContractType.CompoundStaking,
    // 'multi-asset': ContractType.MultiAssetStaking,
};

export const getPathname = (path: string): string =>
    path.includes('?') ? path.split('?')[0] : path;

export const getStakingPools = async (
    version: StakingVersion,
): Promise<StakingPool[]> => {
    const staking = getContract(contract_types[version]);
    const staking_pools = await staking.getPools();

    const mapped = (await Promise.all(
        staking_pools.map((p, idx) => mapPools(p, idx, version)),
    )) as StakingPool[];

    return mapped;
};

export const mapPools = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    version: StakingVersion,
): Promise<StakingPool> => {
    const input_token = p.inputToken
        ? p.inputToken[0].toLowerCase()
        : p.token.toLowerCase();

    const reward_token = p.rewardToken
        ? p.rewardToken[0].toLowerCase()
        : p.token.toLowerCase();

    let _reward;

    const input = getERC20Contract(input_token);
    const logo_store = getContract(ContractType.LogoStore);

    const input_symbol = await input.symbol();
    const input_decimals = await input.decimals();
    const input_logo = await logo_store.logoSource(input_token);

    const _input = {
        address: input_token,
        decimals: input_decimals,
        symbol: input_symbol,
        logo: input_logo,
    };

    if (input_token !== reward_token) {
        const reward = getERC20Contract(reward_token);
        const reward_symbol = await reward.symbol();
        const reward_decimals = await reward.decimals();
        const reward_logo = await logo_store.logoSource(reward_token);

        _reward = {
            address: reward_token,
            decimals: reward_decimals,
            symbol: reward_symbol,
            logo: reward_logo,
        };
    } else {
        _reward = _input;
    }

    const staking = getContract(contract_types[version]);
    const nft = await staking.nftInfo(id);

    return {
        pool_id: `${version}:${id}`,
        apr: Math.round((Number(p.apy) / 10) * 100) / 100 + '%',
        name: `${_input.symbol} / ${_reward.symbol}`,
        lock_period: p.lockPeriodInDays.toString(),
        input_token: _input,
        reward_token: _reward,
        total_staked: p.totalDeposit.toString(),
        hardcap: p.hardCap?.toString() || '1000000000000000000000000000',
        end_date: dayjs(p.endDate.mul(1000).toNumber())
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft.multiplier.toString(),
        nft_multiplier_used: nft.active || false,
        ratio: p.ratio?.toString() || '1',
        is_reward_above_input: p.isRewardAboveInput || false,
        nft_name: nft.name,
    };
};
