import React from 'react';

import { Button } from '@src/components/Button';

export const RequiresSetup: React.FC<{ back: () => void }> = ({ back }) => {
    return (
        <div className="text-center px-4">
            <h2 className="text-2xl mt-12">
                <strong>
                    You need to set up and create a pool before you can set up
                    the NFT boost
                </strong>
            </h2>

            <Button className="w-48 mt-12" dark onClick={back}>
                Back
            </Button>
        </div>
    );
};
