import React, { useState, useEffect } from 'react';

import { Button } from '@src/components';
import { ArrowRight } from '@src/components/Icon';

import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

export const PoolCard: React.FC<{ onClick?: () => void }> = ({
    children,
    onClick,
}) => {
    return (
        <div
            onClick={onClick}
            className={`lg:h-24 my-4 p-3 md:p-5 w-full text-left rounded border border-custom cursor-pointer bg-level-one flex items-center space-x-4`}
        >
            {children}
        </div>
    );
};

export const NoStake: React.FC<{ executeScroll: () => void }> = ({
    executeScroll,
}) => (
    <PoolCard>
        <div className="flex-1">
            <h5 className="text-lg mb-1">
                <strong>Looks like you haven’t staked yet</strong>
            </h5>
            <p className="text-sm text-secondary">
                Stake in pools below to start earning rewards
            </p>
        </div>
        <div>
            <Button className="w-full" onClick={executeScroll}>
                <ArrowRight className="mx-auto" />
            </Button>
        </div>
    </PoolCard>
);

export const ConnectWallet: React.FC = () => {
    const { connect } = useWeb3Onboard();
    return (
        <PoolCard>
            <div className="flex-1">
                <h5>
                    <strong>Connect wallet to see your staked pools</strong>
                </h5>
            </div>
            <div>
                <Button className="w-full" onClick={() => connect({})}>
                    Connect wallet
                </Button>
            </div>
        </PoolCard>
    );
};

export const StakedInAll: React.FC = () => {
    const [clicks, setClicks] = useState(0);
    const audio = new Audio('/audio/splash.mp3');

    useEffect(() => {
        if (audio && clicks > 0 && clicks % 10 === 0) {
            audio.play();
        }
    }, [clicks, audio]);

    return (
        <PoolCard onClick={() => setClicks(clicks + 1)}>
            <div className="flex-1">
                <h5 className="text-lg mb-1">
                    <strong>
                        Looks like you are staking in all active pools
                    </strong>
                </h5>
                <p className="text-sm text-secondary">What a whale 🐋</p>
            </div>
        </PoolCard>
    );
};

export const NoActivePools: React.FC = () => (
    <PoolCard>
        <div className="flex-1">
            <h5 className="text-lg">
                <strong>
                    It looks like there are no active pools at this time
                </strong>
            </h5>
        </div>
    </PoolCard>
);
