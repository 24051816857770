import React from 'react';

import { InfoText } from '../..';

const PoolHeader: React.FC = ({ children }) => {
    return (
        <div className="hidden lg:flex w-full items-center px-5 py-1 text-secondary">
            <div className="sp-col-1">Pools</div>
            <div className="sp-col-2">
                <InfoText
                    info="The estimated yearly returns made from the pool."
                    text="Reward"
                    text_color="text-secondary"
                />
            </div>
            <div className="sp-col-3">
                <InfoText
                    info="Specified APY boost applies if you own an appropriate NFT"
                    text="NFT boost"
                    text_color="text-secondary"
                />
            </div>
            <div className="sp-col-4">Capacity</div>
            <div className="sp-col-5">Your stake</div>
            <div className="sp-col-6">Lock end date</div>
            <div className="sp-col-7">{children}</div>
        </div>
    );
};

export default PoolHeader;
