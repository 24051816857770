import React, { useState } from 'react';

import { InputProps } from '@src/ts/props';

import { Checkbox } from './Checkbox';
import { Label } from './Label';

import { containerClasses, inputClasses } from './util';

export const Input: React.FC<InputProps> = ({
    className,
    id,
    inputClassName,
    label,
    name,
    onChange,
    placeholder,
    type = 'text',
    value,
    error,
    inset,
    tooltip,
    step,
    min,
}) => {
    const [focused, setFocused] = useState(false);

    if (type === 'checkbox') {
        return (
            <Checkbox
                id={id}
                label={label}
                tooltip={tooltip}
                error={error}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
            />
        );
    }

    const container_classes = containerClasses(inputClassName, focused, inset);
    const input_classes = inputClasses(focused, inset);

    return (
        <div className={`${className || ''}`}>
            <div className="flex space-between items-center text-secondary">
                {label && <Label id={id} label={label} tooltip={tooltip} />}
                {!!error && <p className="text-red-500">{error}</p>}
            </div>
            <div className={container_classes}>
                <input
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setFocused(false);
                    }}
                    data-testid={id}
                    className={input_classes}
                    id={id}
                    name={name}
                    onChange={onChange}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    step={step}
                    min={min}
                />
                {inset && inset}
            </div>
        </div>
    );
};

export { CurrencyInput } from './CurrencyInput';
