import { FormData } from '@src/ts/interfaces';
import { PoolForm } from '@src/ts/props';

export const getPoolFormData = (ticker: string): FormData[] => [
    {
        label: 'Token address',
        name: 'token',
        type: 'text',
        placeholder: 'Enter the token address',
        tooltip: 'The contract address of the token to be used for staking',
        required: true,
    },
    {
        label: 'Pool capacity',
        name: 'hardcap',
        type: 'number',
        placeholder: 'Enter pool capacity',
        inset: ticker,
        tooltip: 'The maximum number of tokens that can be staked',
        required: true,
    },
    {
        label: 'APY',
        name: 'apy',
        type: 'number',
        placeholder: 'Enter pool APY',
        inset: '%',
        tooltip: 'The annual percentage reward for the pool',
        step: 0.1,
        required: true,
    },
    {
        label: 'Lock period',
        name: 'lock_period',
        type: 'number',
        placeholder: 'Enter pool lock days',
        inset: 'days',
        tooltip: 'The number of days a users stake will be locked for',
        step: 1,
        required: true,
    },
    {
        label: 'Pool expiration date',
        name: 'end_date',
        tooltip: 'The date at which users can no longer stake tokens',
        type: 'datetime-local',
        required: true,
    },
];

export const getNFTFormData = (
    boost_percent: string,
    activated: boolean,
): FormData[] => [
    {
        label: 'Contract address',
        name: 'contract',
        type: 'text',
        tooltip: 'The contract address of the required NFT',
        placeholder: 'Enter the NFT contract address',
        required: true,
    },
    {
        label: 'NFT name',
        name: 'name',
        type: 'text',
        tooltip:
            'The name of the required NFT that will be displayed to the users',
        placeholder: 'Enter the required NFT name',
        required: true,
    },
    {
        label: 'Boost multilpier',
        name: 'multiplier',
        type: 'number',
        placeholder: '0',
        inset: boost_percent,
        step: 0.1,
        tooltip:
            'The multiplier used for calculating NFT boost. Supports 1 decimal place',
        required: true,
        min: 1,
    },
    {
        label: 'First token ID to include',
        name: 'start_idx',
        type: 'number',
        placeholder: '0',
        tooltip: 'First token ID to include',
        step: 1,
        required: true,
    },
    {
        label: 'Last token ID to include',
        name: 'end_idx',
        type: 'number',
        placeholder: '0',
        tooltip: 'Last token ID to include',
        step: 1,
        required: true,
    },
    {
        label: 'NFT Boost status',
        name: 'active',
        type: 'checkbox',
        required: true,
        placeholder: `NFT boost is ${activated ? '' : 'not '}activated`,
    },
];

export const validateInput = (
    form_data: FormData[],
    state: { [key: string]: unknown },
): { has_required_fields: boolean; errors: PoolForm } => {
    const errors = {} as PoolForm;
    const has_required_fields =
        form_data.filter(({ name, label, required }) => {
            if (
                required &&
                [null, undefined, ''].includes(state[name] as string)
            ) {
                errors[name] = label + ' required';
                return false;
            }
            return true;
        }).length === form_data.length;

    return { has_required_fields, errors };
};
