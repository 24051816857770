import React, { useRef, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { useAppSelector } from '@src/hooks';

import PoolHeader from './PoolHeader';
import PoolItem from './PoolItem';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

import { ConnectWallet, NoStake, NoActivePools, StakedInAll } from './Cards';
import { StakingPool } from '@src/ts/interfaces';
import { BigNumber } from '@ethersproject/bignumber';
import { effectiveApy } from '@src/utils/staking';

const PoolsContainer: React.FC<{ title: string }> = ({ title, children }) => {
    return (
        <div className="text-primary mb-8 mt-12">
            <h2 className="text-3xl mb-8">
                <strong>{title}</strong>
            </h2>

            <PoolHeader />

            {children}
        </div>
    );
};

const getApr = (p: StakingPool) => {
    const {
        pool_id,
        apr,
        nft_multiplier = 10,
        nft_multiplier_used,
        user_multiplier = 10,
    } = p;

    const [current_version] = pool_id.split(':');

    const APR =
        current_version === 'compound'
            ? effectiveApy(parseFloat(apr) / 100, 365) * 100
            : parseFloat(apr);

    const boost = Math.round((parseFloat(apr) * nft_multiplier) / 10);

    const has_boost =
        nft_multiplier_used &&
        BigNumber.from(user_multiplier).eq(nft_multiplier);

    return (has_boost && boost) || APR;
};

export const StakingPools: React.FC = () => {
    const { account } = useWeb3Onboard();
    const { pools } = useAppSelector((state) => state.staking);
    const active_pools_ref = useRef(null);
    const executeScroll = () => active_pools_ref.current.scrollIntoView();

    const [has_stake, setHasStake] = useState([]);
    const [active_no_stake, setActiveNoStake] = useState([]);

    useEffect(() => {
        setHasStake(pools.filter(({ has_stake }) => has_stake));
        setActiveNoStake(
            pools.filter(({ has_stake, end_date }) => {
                return !has_stake && dayjs().isBefore(dayjs(end_date));
            }),
        );
    }, [pools]);

    return (
        <div className="my-8">
            <PoolsContainer title="Staked pools">
                {has_stake.length > 0 ? (
                    [...has_stake]
                        .sort(
                            (a, b) =>
                                dayjs(a.last_user_deposit)
                                    .add(a.lock_period, 'days')
                                    .unix() -
                                dayjs(b.last_user_deposit)
                                    .add(b.lock_period, 'days')
                                    .unix(),
                        )
                        .map((p) => <PoolItem pool={p} key={p.pool_id} />)
                ) : account ? (
                    <NoStake executeScroll={executeScroll} />
                ) : (
                    <ConnectWallet />
                )}
            </PoolsContainer>

            <div ref={active_pools_ref}>
                <PoolsContainer title="Active pools">
                    {active_no_stake.length > 0 ? (
                        [...active_no_stake]
                            .sort((a, b) => getApr(b) - getApr(a))
                            .map((p) => <PoolItem pool={p} key={p.pool_id} />)
                    ) : has_stake.length > 0 ? (
                        <StakedInAll />
                    ) : (
                        <NoActivePools />
                    )}
                </PoolsContainer>
            </div>
        </div>
    );
};
