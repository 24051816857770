import { BigNumber, BigNumberish } from '@ethersproject/bignumber';

import { StakingPool } from '@src/ts/interfaces';

const days = 24 * 60 * 60;

export const estimateReward = (
    bal: BigNumberish = BigNumber.from(0),
    apr = '0',
    lock_period = '0',
    multiplier = '10',
    input_decimals = 18,
    reward_decimals = 18,
): BigNumber => {
    const time = Number(lock_period) * days;

    const amount = BigNumber.from(bal)
        .mul(time)
        .mul(parseFloat(apr) * 10)
        .div(365 * days * 1000)
        .mul(multiplier)
        .div(10);

    return input_decimals > reward_decimals
        ? BigNumber.from(amount).div(10 ** (input_decimals - reward_decimals))
        : BigNumber.from(amount).mul(10 ** (reward_decimals - input_decimals));
};

export const parseLockPeriod = (pool: StakingPool): string =>
    pool && pool.lock_period && pool.lock_period !== '0'
        ? pool.lock_period
        : '1';

export const effectiveApy = (r: number, n: number): number =>
    (1 + r / n) ** n - 1;
