import React from 'react';

import { Card } from '@src/components';
import { AnalyticsItem } from './AnalyticsItem';

const card_classes = 'border bg-level-one items-center space-y-4 h-44 flex';

export const AnalyticsCard: React.FC<{
    title: string;
    items: { text: string; title: string }[];
    className?: string;
    image?: string;
}> = ({ title, items, className = '', image }) => {
    return (
        <div className={`text-primary flex-1 ${className}`}>
            <h3 className="text-xl mb-4">
                <strong>{title}</strong>
            </h3>
            <Card className={card_classes}>
                <div className="p-6 w-full space-y-4">
                    {items.map(({ text, title }) => (
                        <AnalyticsItem text={text} title={title} key={title} />
                    ))}
                </div>
                {image && (
                    <img
                        className="h-3/4 mr-8"
                        src={image}
                        alt="account-image"
                    />
                )}
            </Card>
        </div>
    );
};
