import { useState } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import { Input } from '@src/components/Input';
import { Button } from '@src/components/Button';
import { getNFTFormData, validateInput } from '@src/utils/form';

import { useDecubateContract } from '@src/hooks/useContract';
import { ContractType } from '@src/ts/constants';
import { Dictionary } from '@src/ts/interfaces';

import { RequiresSetup } from './RequiresSetup';
import { getPathname } from '@src/utils/getters';

const Inset = (text: string) => (
    <div className="px-2 w-28">
        <strong>{text}</strong>
    </div>
);

const getNFTParams = (
    idx: string,
    { active, name, start_idx, end_idx, multiplier, contract }: Dictionary,
) => [
    idx,
    name,
    contract,
    active,
    Math.round(Number(multiplier) * 10),
    start_idx,
    end_idx,
];

export const NFTBoost: React.FC<{
    add?: boolean;
    back: () => void;
    state: Dictionary;
    handleChange: (name: string, value: string | boolean) => void;
    apy: string;
    nft_has_changes: boolean;
    idx: string;
}> = ({ idx, add, back, state = {}, handleChange, apy, nft_has_changes }) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const router = useRouter();

    const [staking] = useDecubateContract(ContractType.CompoundStaking, true);

    if (add) {
        return <RequiresSetup back={back} />;
    }

    const boost_percent = `+ ${(
        Number(apy) * Number(state.multiplier) -
        Number(apy)
    ).toFixed(2)} %`;

    const form_data = getNFTFormData(boost_percent, state?.active as boolean);

    const handleSave = async () => {
        setErrors({});
        const { errors, has_required_fields } = validateInput(form_data, state);
        if (!has_required_fields) {
            setErrors(errors);
            return;
        }

        setLoading(true);
        try {
            toast.info('Awaiting successful transaction');

            const params = getNFTParams(idx, state);
            const tx = await staking.setNFT(...params);
            await tx.wait();

            toast.success('Successfully updated NFT boost values');
            setLoading(false);
            router.push({
                pathname: getPathname(router.asPath),
                query: { is_nft_boost: true },
            });
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
        }
    };

    return (
        <div className="mt-8">
            {form_data.map(
                ({
                    name,
                    label,
                    type,
                    placeholder,
                    tooltip,
                    inset,
                    step,
                    min,
                }) => (
                    <Input
                        className={`my-4`}
                        key={name}
                        value={
                            state[name] as string | number | readonly string[]
                        }
                        name={name}
                        id={name}
                        label={label}
                        error={errors[name]}
                        onChange={({
                            target: { type, name, value, checked },
                        }) =>
                            handleChange(
                                name,
                                type === 'checkbox' ? checked : value,
                            )
                        }
                        placeholder={placeholder}
                        type={type}
                        inset={inset && Inset(inset)}
                        tooltip={tooltip}
                        step={step}
                        min={min}
                    />
                ),
            )}

            <div className="flex space-x-4 items-center mt-8">
                <div className="flex-1">
                    <Button
                        dark
                        onClick={back}
                        className="w-full"
                        id="back_button"
                    >
                        Back
                    </Button>
                </div>
                <div className="flex-1">
                    <Button
                        onClick={handleSave}
                        disabled={!nft_has_changes || loading}
                        loading={loading}
                        className="w-full"
                        id="save_changes"
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </div>
    );
};
