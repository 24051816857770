import React, { useEffect, useState } from 'react';
import { isAddress } from '@ethersproject/address';

import { Input } from '@src/components/Input';
import { Select } from '@src/components/Select';
import { getContract } from '@src/contracts';
import { StakingPool } from '@src/ts/interfaces';

import { ContractType } from '@src/ts/constants';
import dayjs from 'dayjs';
import { parseBalance } from '@src/utils/web3';
import { cancellablePromise } from '@src/utils/promise';

import { getPoolLabel } from './util';

const compounder = getContract(ContractType.Vault);
const staking = getContract(ContractType.CompoundStaking);

export const WalletLookUp: React.FC<{ pools: StakingPool[] }> = ({ pools }) => {
    const [selected, setSelected] = useState('');
    const [wallet, setWallet] = useState('');
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState(null);

    const pool = pools.find(({ pool_id }) => pool_id === selected);

    useEffect(() => {
        if (pool && isAddress(wallet)) {
            setLoading(true);
            // eslint-disable-next-line
            const [_, pool_id] = selected.split(':');
            const p = compounder.users(pool_id, wallet).then(async (res) => {
                const multiplier = await staking.calcMultiplier(
                    pool_id,
                    wallet,
                );
                const user = {
                    stake: res.totalInvested,
                    unlock_date:
                        res.lastDepositedTime.toNumber() +
                        parseInt(pool.lock_period) * 86400,
                    multiplier: multiplier !== 10,
                };
                return user;
            });

            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((user) => {
                    setUser(user);
                    setLoading(false);
                })
                .catch(() => true);

            return cancel;
        } else {
            setUser(null);
        }
    }, [pool, wallet]);

    return (
        <div className="max-w-2xl mx-auto my-20">
            <h3 className="text-xl mb-8">
                <strong>Wallet lookup</strong>
            </h3>

            <div className="p-4 rounded bg-level-one border border-custom">
                <Select
                    options={pools}
                    selected={selected}
                    getId={(pool: StakingPool) => pool.pool_id}
                    getLabel={getPoolLabel}
                    onSelect={(id) => setSelected(id)}
                    label={'Pool'}
                    loading={loading}
                    placeholder="Select pool"
                    className="my-2"
                    id="staking-pool"
                />
                <Input
                    id="wallet-address"
                    label={'Wallet address'}
                    placeholder="Enter wallet address"
                    name="wallet"
                    className="mb-2 mt-4"
                    value={wallet}
                    onChange={(e) => setWallet(e.target.value)}
                />

                {user && (
                    <div className="mt-8">
                        <h5 className="text-lg mb-4">
                            <strong>Overview</strong>
                        </h5>

                        <div className="flex items-center my-1">
                            <div className="flex-1 text-secondary">
                                Total stake
                            </div>
                            <h5 className="text-lg">
                                <strong>
                                    {parseBalance(user.stake)}{' '}
                                    {pool?.input_token.symbol}
                                </strong>
                            </h5>
                        </div>
                        <div className="flex items-center my-1">
                            <div className="flex-1 text-secondary">
                                Unlock date
                            </div>
                            <h5 className="text-lg">
                                <strong>
                                    {user.stake.gt(0)
                                        ? dayjs(user.unlock_date * 1000).format(
                                              'DD/MM/YY HH:mm',
                                          )
                                        : 'None'}
                                </strong>
                            </h5>
                        </div>
                        <div className="flex items-center my-1">
                            <div className="flex-1 text-secondary">
                                NFT boost
                            </div>
                            <h5 className="text-lg">
                                <strong>{user.multiplier.toString()}</strong>
                            </h5>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
